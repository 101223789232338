import { API } from "aws-amplify";


export async function fetchInternalUser(userType:string = "internal", searchWord:string = "") {
    let url  = `usergroup/users?userType=${userType}&searchWord=${searchWord}`;
    try {
        const response = await API.get("api", url, {});
        console.log(response)
        return response.groupUsers
    } catch (e) {
        throw e
    }
}

export async function fetchSites(databaseId:any) {
    try {
        const response = await API.get("api", `database/${databaseId}/locations/sites`, {});
        console.log(response)
        return response
    } catch (e) {
        throw e
    }
}


export async function fetchUserRoles(api = API) {
    try {
        const response = await api.get("api", "user-roles", {});
        console.log(response)
        return response.message.Items
    } catch (e) {
        throw e
    }
}

export async function fetchRoles(api = API) {
    try {
        const response = await api.get("api", "roles", {});
        console.log(response)
        return response.message.Items
    } catch (e) {
        console.error(e);
        throw e
    }
}

export async function postRoles(body: any, api = API) {
    try {
        const response = await api.put("api", "user-roles", { body: body });
        console.log(response)
        return response.message.Items
    } catch (e) {
        throw e
    }
}